<!--
 * @Author: mulingyuer
 * @Date: 2021-04-08 15:42:35
 * @LastEditTime: 2021-05-17 17:03:03
 * @LastEditors: mulingyuer
 * @Description: 投票列表
 * @FilePath: \saas-admin-vue\src\modules\vote\views\vote-list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page vote-list" v-loading="loading">
    <div class="head">
      <div class="head-group-btn top-btn">
        <el-button v-if="postData.tab !=='deleted'" type="primary" size="small" @click="onAdd">新增</el-button>
      </div>
      <vote-filter :filter-change="onFilter" :exportData="postData" />
    </div>
    <div class="list-content">
      <list-tabs v-model="postData.tab" :tabs="tabArr" @tab-click="handleTabClick" />
      <div class="list-btn">
        <el-button v-if="postData.tab ==='deleted'" type="success" size="small" @click="onMultipleReduction">
          还原
        </el-button>
        <el-button size="small" type="danger" @click="onMultipleDelete">删除</el-button>
      </div>
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe @sort-change="sortChange"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column show-overflow-tooltip v-for="th in thead" :key="th.prop" :prop="th.prop"
          :label="th.label" :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
              <el-button v-if="postData.tab==='normal'" type="text" size="mini"
                @click="onOpenViewDialog(row.id)">查看</el-button>
              <el-button type="text" size="mini" :disabled="row.status!==-1" @click="onEdit(row.id)">编辑
              </el-button>
              <el-button v-if="postData.tab==='deleted'" type="text" size="mini" @click="onReduction(row.id)">
                还原
              </el-button>
              <el-button type="text" size="mini" @click="onDelete(row.id)">删除</el-button>
            </template>
            <template v-else-if="th.prop === 'is_open'">{{row.is_open ? '所有人可见':'投票后可见'}}</template>
            <list-status-text v-else-if="th.prop === 'status_text'" :text="row.status_text"/>
            <template v-else>{{row[th.prop] | placeholder}}</template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
    <!-- 查看弹窗 -->
    <view-dialog v-model="openViewDialog" :view-id="viewDialogId" />
  </div>
</template>
<script>
import { voteList, softDelete, hardDelete, putBack } from "../api/vote-list";
import Pagination from "@/base/components/Default/Pagination";
import ViewDialog from "../components/vote-list/ViewDialog";
import VoteFilter from "../components/vote-list/Filter";
import ListStatusText from "@/base/components/List/ListStatusText";
export default {
  data() {
    return {
      loading: false, //加载中
      //提交的数据
      postData: {
        tab: "normal",
      },
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "进行中", name: "ongoing" },
        { label: "已结束", name: "closes" },
        { label: "回收站", name: "deleted" },
      ],
      pageData: {}, //api请求回的数据
      //表头数据
      thead: [
        { label: "标题", prop: "title", minWidth: 140 },
        { label: "类型", prop: "type_text", minWidth: 80 },
        { label: "状态", prop: "status_text", minWidth: 80 },
        { label: "投票票数", prop: "vote_count", minWidth: 120 },
        { label: "投票结果", prop: "is_open", minWidth: 100 },
        { label: "开始时间", prop: "start_time", minWidth: 150 },
        { label: "结束时间", prop: "end_time", minWidth: 150 },
        { label: "参与人数", prop: "total", minWidth: 100, sort: "custom" },
        { label: "操作", prop: "operate", minWidth: 160, fixed: "right" },
      ],
      tbody: [], //表格数据
      activeVoteIds: [], //选中的表格数据id
      openViewDialog: false, //是否显示查看弹窗
      viewDialogId: "", //需要查看的数据id
    };
  },
  methods: {
    //获取列表数据
    getVoteList(pageData) {
      this.loading = true;
      voteList(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
          // console.log(data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      this.getVoteList();
    },
    //tab切换
    handleTabClick() {
      //name已经v-model绑定到postData了
      Object.assign(this.postData, { page: 1 });
      this.getVoteList();
    },
    //排序事件 descending=倒序，ascending=升序,api默认倒序，所以null=descending
    sortChange({ prop, order }) {
      const is_desc = order === "ascending" ? 0 : 1;
      Object.assign(this.postData, { is_desc, order_by: prop }, { page: 1 });
      this.getVoteList();
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getVoteList();
    },
    //table多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.activeVoteIds = arr.map((item) => item.id);
      } else {
        this.activeVoteIds = [];
      }
    },
    //单个删除
    onDelete(id, multiple = false) {
      let infoText = `您是否要删除${multiple ? "选中的" : "该条"}投票数据？`;
      let api = softDelete;
      if (this.postData.tab === "deleted") {
        infoText += "删除后将不可恢复！";
        api = hardDelete;
      }
      this.$confirm(infoText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.loading = true;
          const postData = { id };
          api(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              //更新
              this.getVoteList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    //多选删除
    onMultipleDelete() {
      if (this.activeVoteIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onDelete(this.activeVoteIds, true);
    },
    //单个还原
    onReduction(id, multiple = false) {
      this.$confirm(
        `是否还原${multiple ? "选中的" : "该条"}投票数据？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        }
      )
        .then(() => {
          this.loading = true;
          const postData = { id };
          putBack(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              //更新
              this.getVoteList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    //多个还原
    onMultipleReduction() {
      if (this.activeVoteIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onReduction(this.activeVoteIds, true);
    },
    //查看
    onOpenViewDialog(id) {
      // this.viewDialogId = id;
      // this.openViewDialog = true;
      this.$router.push({
        name: 'VoteAnalysis',
        query: {id}
      })
    },
    //新增
    onAdd() {
      this.$router.push({ name: "VoteAddEdit" });
    },
    //编辑
    onEdit(id) {
      this.$router.push({ name: "VoteAddEdit", query: { id } });
    },
  },
  created() {
    //获取列表数据
    this.getVoteList();
  },
  components: {
    ListStatusText,
    Pagination,
    ViewDialog,
    VoteFilter,
  },
};
</script>
