<!--
 * @Author: mulingyuer
 * @Date: 2021-04-09 17:49:59
 * @LastEditTime: 2021-09-09 10:09:35
 * @LastEditors: aleaner
 * @Description: 筛选
 * @FilePath: \vote\components\vote-list\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <layout-filter :onFilter="onFilter" :onReset="onReset" :onExport="onExport" :toggleCollapseEnable="true">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <el-form-item label="搜索：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入搜索内容"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="开始时间：" start-placeholder="开始日期" :start-time.sync="form.start_time"
          start-prop="start_time" end-placeholder="结束日期" :end-time.sync="form.end_time" end-prop="end_time" />
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="结束时间：" start-placeholder="开始日期" :start-time.sync="form.finished_start_time"
          start-prop="finished_start_time" end-placeholder="结束日期" :end-time.sync="form.finished_end_time"
          end-prop="finished_end_time" />
      </div>

    </el-form>
  </layout-filter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { exportDocument } from "../../api/vote-list";
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        finished_start_time: -1,
        finished_end_time: -1,
        keyword: "",
      },
      rules: {},
    };
  },
  methods: {
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //导出
    onExport() {
      return exportDocument({ ...this.exportData, ...this.form }).catch(
        (err) => {
          // console.log(err);
        }
      );
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped>
</style>