<!--
 * @Author: mulingyuer
 * @Date: 2021-04-08 17:33:47
 * @LastEditTime: 2021-04-27 16:13:47
 * @LastEditors: mulingyuer
 * @Description: 查看弹窗
 * @FilePath: \saas-admin-vue\src\modules\vote\components\vote-list\ViewDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="查看" :visible.sync="show" width="500px" @close="onClose" @open="getVoteDetail"
    class="dialog-vertical view-dialog">
    <div class="content" v-loading="loading">
      <div class="top">
        <span class="type">投票方式：{{detailData.more_text}}</span>
        <span class="people">参与人数：{{detailData.total}}</span>
        <span class="vote">投票票数：{{detailData.vote_total}}</span>
      </div>
      <div class="bottom">
        <div class="item" v-for="item in optionsData" :key="item.id">
          <h2 class="title">{{item.item.name}}</h2>
          <div class="progress">
            <div class="info">得票数：{{item.total}}</div>
            <el-progress :percentage="item.ratio"></el-progress>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button type="primary" size="small" :disabled="loading" :loading="exportLoading" @click="onExport">导出
      </el-button>
    </template>
  </el-dialog>
</template>
<script>
import { voteDetail, exportCheck } from "../../api/vote-list";
import { aElementDownload } from "@/base/utils/tool";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //查看的id
    viewId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false, //加载中
      detailData: {}, //查看的数据
      optionsData: [], //选项数据
      exportLoading: false, //导出中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //关闭弹窗回调
    onClose() {
      this.exportLoading = false;
      this.detailData = {};
      this.optionsData = [];
    },
    //获取查看的数据
    getVoteDetail() {
      this.loading = true;
      const postData = { id: this.viewId };
      voteDetail(postData)
        .then((res) => {
          const { data } = res;
          this.detailData = data;
          this.optionsData = data.my_options;
          // console.log(data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //导出
    onExport() {
      this.exportLoading = true;
      const postData = { id: this.viewId };
      exportCheck(postData)
        .then(() => {
          this.$message.success("导出成功！");
          this.show = false;
          this.exportLoading = false;
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.view-dialog {
  .content {
    padding: 20px 0;
    .top {
      margin-bottom: 30px;
      font-size: 14px;
      color: #666;
      span + span {
        margin-left: 20px;
      }
    }
    .bottom {
      .item {
        & + .item {
          margin-top: 25px;
        }
        .title {
          font-size: 16px;
          line-height: 1;
          margin-bottom: 10px;
          color: #333;
        }
        .progress {
          .info {
            margin-bottom: 5px;
            font-size: 14px;
            color: #333;
          }
        }
      }
    }
  }
}
</style>